import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import useMedia from 'use-media'

import Layout from '../layout'
import {
  SEO,
  Products,
  Video,
  MainUpdate,
  Solutions,
  Hero,
  Numbers,
  MainHeadings,
  Image,
} from '../components'

import DeveloperWebsiteOgImage from '../assets/images/developer-website-seo-image.png'

export const query = graphql`
  query homepageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { ne: "hackathon-terms" } } }
      limit: 1
    ) {
      nodes {
        fields {
          index
        }
        frontmatter {
          slug
          author
          title
          date(formatString: "DD MMMM, YYYY")
          ogimage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const Homepage = ({ data }) => {
  const {
    frontmatter: { title, slug, author, date, ogimage },
    fields: { index },
  } = data.allMarkdownRemark.nodes[0]

  const isSmall = useMedia({ maxWidth: 768 })

  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Chat framework for innovative teams"
        description="Messaging APIs, chat widgets and other building blocks of remarkable customer service
        experience."
        image={DeveloperWebsiteOgImage}
      />
      <div className="u-Pb-xl md:u-Pb-2xl u-bg-black2">
        <MainHeadings
          title={
            <Fragment>
              Chat framework <br /> for innovative teams
            </Fragment>
          }
          subtitle={
            <Fragment>
              The building blocks of remarkable <br /> customer service
              experience.
            </Fragment>
          }
        />
        <Video
          preview="homepageVideo"
          src={process.env.GATSBY_PLATFORM_VIDEO_URL}
        />
        <Products
          items={[
            {
              name: 'Programmable Chat',
              description:
                'A fully programmable messaging service makes it easy to handle all kinds of communication.',
              to: '/programmable-chat/',
            },
            {
              name: 'Open Chat Widget',
              description:
                'A customizable chat widget for every use case supports rich messages, apps and interactive elements.',
              to: '/open-chat-widget/',
            },
            {
              name: 'Open Agent App',
              description:
                'The Agent App with multiple integration interfaces suits specific needs of any customer service team.',
              to: '/open-agent-app/',
            },
            {
              name: 'Data & Reporting APIs',
              description:
                'A wide range of datapoints and export APIs to build all variety of metrics and reporting tools for data-driven teams.',
              to: '/data-and-reporting/',
            },
            {
              name: 'Omnichannel APIs',
              description:
                'Simple yet flexible APIs and services to funnel all the channels into a single chatting experience.',
              to: '/omnichannel/',
            },
            {
              name: 'Private Apps',
              description:
                'A robust system for creating and managing private apps to let teams make the best of their environments.',
              to: '/private-apps/',
            },
          ]}
        />
      </div>
      <div className="u-Py-md md:u-Pt-2xl u-bg-black-3">
        <div className="o-container u-Mb-md">
          <h2 className="u-text-p2 u-mb-xl">
            Marketplace open <br /> for partners and creators
          </h2>
          <Image type="partnerProgram" style={{ borderRadius: '16px' }} />
        </div>
        <Solutions
          items={[
            {
              withButton: true,
              name: 'Developer Program',
              description:
                'Build and monetize apps for 30,000 companies worldwide. We’ll help you on the way to our Marketplace!',
              to: '/developer-program/',
            },
            {
              name: 'Partner Program',
              description:
                'Grow your business with the leading customer service platform. Become our Affiliate or Solution Partner.',
              externalLink: process.env.GATSBY_PARTNER_WEBSITE_URL,
            },
            {
              name: 'Open Marketplace',
              description:
                'Help us create an open Marketplace of products and services that bring innovation to online communication.',
              label: 'Explore',
              externalLink: `${
                process.env.GATSBY_PRODUCT_WEBSITE_URL
              }marketplace/`,
            },
          ]}
        />
      </div>
      <div className="u-Py-xl u-Pt-xl u-bg-black-1">
        <Hero
          name="Pawel Halas"
          position="LiveChat Partner Program Manager"
          quotation={
            <Fragment>
              With the Partner and Developer Programs, we’re building a
              community of innovators.
            </Fragment>
          }
          image="pawelHalas"
          imageStyles={{ top: isSmall ? '0' : '-50px' }}
        />
        <Numbers
          items={[
            {
              title: '+30,000 companies',
              description:
                'Distribute your services to over 30k companies worldwide.',
            },
            {
              title: '+300,000 hits every minute',
              description: 'Our widgets are displayed 300k times every minute.',
            },
            {
              title: '+18 years on the market',
              description:
                'Our core product has been serving companies for 18 years.',
            },
            {
              title: '+200 partners, +180 apps',
              description:
                'We’re a friendly company that puts teamwork first. Hello!',
            },
          ]}
        />
      </div>
      <section className="u-Pt-xl md:u-Pt-2xl u-Pb-xl u-bg-black-3">
        <div className="o-container">
          <h2 className="u-text-p2 u-mb-xl">Platform Updates</h2>
        </div>
        <MainUpdate
          wider
          eyeCatcher="Latest update"
          title={title}
          author={author}
          date={date}
          image={ogimage}
          slug={slug}
          index={index}
        />
      </section>
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object,
}

export default Homepage
